import React, { useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { AiOutlineUser } from "react-icons/ai"
import { IoShareSocialOutline } from "react-icons/io5"
import { GoLocation } from "react-icons/go"
import { VscTools } from "react-icons/vsc"
import { FiAward } from "react-icons/fi"
import { RiLightbulbFlashLine } from "react-icons/ri"
import OrangeCube from '../images/svg/ORANGE_cube.inline.svg'


export const query = graphql`
  {
    allTagDataJson {
      nodes {
        class
        definition
        definitionDescription
        id
        name
      }
      totalCount
    }
  }
`

const ContentAbout = (props) => {
  const profilePic = "../images/content/ProfilePic.jpeg"

  const data = useStaticQuery(query)
  const tags = data.allTagDataJson.nodes

  const servers = tags.filter(item => item.class === "servers-languages");
  const frameworks = tags.filter(item => item.class === "frameworks-platforms");
  const technologies = tags.filter(item => item.class === "technologies");
  const software = tags.filter(item => item.class === "software-tools");
  
  const defText = useRef()
  const defDesc = useRef()

  const tagRolloverHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // console.log("rollover")
    const def = event.currentTarget.getAttribute("data-title")
    const desc = event.currentTarget.getAttribute("data-desc")
    
    defText.current.innerHTML = def
    defDesc.current.innerHTML = desc
  }

  const tagRollOutHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // console.log("rollout")
      defDesc.current.innerHTML = ""
      defText.current.innerHTML = ""
  }

  return (
    <section className="section container">
      <div className="content" id="main-content">
        <section className="about">
          <div className="about-header">
            
            <div className="top-title">
                <div>
                  <OrangeCube aria-hidden="true" />
                  <h1 className="h1"><span>About</span></h1>
                </div>
            </div>
            
          </div>
        </section>
        <div className="upper-content">
        <section className="profile-story" aria-label="My profile story">
          
         

          <div className="my-story">
            <h2 className="h1">
              <span className="sr-only">My name is </span>Zachary Joel
              Fuller
            </h2>
            <p>I am a web developer who has evolved from a diverse background in media arts. I am at-heart an inventor. I&nbsp;love to make things.</p>

            <p>I enjoy bridging the gap between designer and programmer, communicating in both deep tech and artsy abstracts. I communicate well with designers and understand their concerns having come from a graphics background. Typography and layout are as familiar as technology terms such as <abbr title="Document Object Model">DOM</abbr>, <abbr title="Responsive Web Design">RWD</abbr>, <abbr title="Syntactically Awesome Style Sheets">SASS</abbr>, server-side, deployment and many others. I feel it is important to have someone like myself on a team who can understand and translate the needs of both designers and developers to better establish team cohesion.</p>
          
            <p>Through my experience in leadership, planning, training and hands-on production development of web and rich media projects, I have learned to:</p>
            
            <ul className="principles">
              <li>Abide by the principles of user-centric design.</li>
              <li>Champion the user experience. </li>
              <li>Excel at generating scalable and sustainable solutions.</li>
              <li>Always take a holistic view of process and collaboration. </li>
            </ul>
          
            <p>My journey has taken me from the front-end to the back-end and the front-end again, so the term full-stack developer fits well enough, though I prefer to stay within the stack of great user experiences on every project I touch.</p>

            <p>Whether the end result manifests on screen or something I can physically grasp, inventing has been at the core of my evolution. You can call it passion, but if asked I say making something from nothing is what I have come to love. One of my heroes Brian Eno has said:</p>

            <blockquote>
              &#8220;I think what’s so interesting&mdash;what would really be
              a lesson that everybody should learn&mdash;is that things come out of
              nothing. Things <em>evolve</em> out of nothing. It gives people
              confidence in their own lives to know that’s how things
              work.&#8221;
            </blockquote>
            
            <p>Since the digital landscape is always changing, I accept new ideas and ways of working as positive, fun challenges, so let’s create something from nothing! To that end, here is a look at some tools I&nbsp;use to get jobs done:</p>

          </div>
        </section>

        <section className="profile-info" aria-label="Profile Information and Contact for Zachary Joel Fuller">
           
            <StaticImage
              src={profilePic}
              alt="Profile Picture of Mr. Fuller"
              width={167}
              className="profile-pic"
            />
             <h2 className="h3 careers">
                  <span className="sr-only">Progression of career fields</span>
                  <span>Video Games</span>&nbsp;<span className="sr-only">to </span><span aria-hidden="true">&gt;</span> 
                  <span> Graphics</span>&nbsp;<span className="sr-only">to </span><span aria-hidden="true">&gt;</span> 
                  <span> 3D</span>&nbsp;<span className="sr-only">to </span><span aria-hidden="true">&gt;</span> 
                  <span> Multimedia</span>&nbsp;<span className="sr-only">to </span><span aria-hidden="true">&gt;</span>
                  <span> Web&nbsp;Design</span>&nbsp;<span className="sr-only">and finally to </span><span aria-hidden="true">&gt;</span>
                  <span> Web&nbsp;Development</span>
              </h2>
            <div className="info-group" role="group" aria-label="Contact Information">
              <AiOutlineUser aria-hidden="true" />
              <h2 className="h2">Contact</h2>
              <p>
                <a href="mailto:jfuller@archebyte.com" className="dark">
                  jfuller@archebyte.com
                </a>
              </p>
              <a href="tel:5109267009" className="dark">510.926.7009</a>
            </div>
            <div className="info-group" role="group" aria-label="Social Media Links">
              <IoShareSocialOutline aria-hidden="true" />
              <h2 className="h2">Social</h2>
              <p>
                <a href="https://www.linkedin.com/in/jfuller/" target="_blank" rel="noreferrer noopener">
                  LinkedIn
                </a>
              </p>
              <p>
                <a href="https://twitter.com/archebyte_shout" target="_blank" rel="noreferrer noopener">
                  Twitter
                </a>
              </p>
              <p>
                <a href="https://github.com/archebyte" target="_blank" rel="noreferrer noopener">
                  GitHub
                </a>
              </p>
            </div>
            <div className="info-group" role="group" aria-label="Geographic Location">
              <GoLocation aria-hidden="true" />
              <h2 className="h2">Location</h2>
              <p>Winters, CA</p>
            </div>
           
          </section>
         
        </div>


        <div className="lower-content">
            <section className="tools"> 
              <div className="info-group" role="group" aria-label="Tools I use for development">
                <VscTools aria-hidden="true" />
                <h2 className="h2">Tools</h2>
              </div>
              <div className="info">
                <div className="global-tags">

                  <div className="work-tags">
                      <div id="definition-container" className="definition" aria-live="polite" aria-atomic="true">
                          <dfn id="def-text" ref={defText}></dfn>
                          <p id="def-desc" ref={defDesc}></p>
                      </div>
                      <div className="tag-container" role="group" aria-label="Work Tags">
                          
                          <ul className="servers-languages">
                            {servers.map((item, index) => {
                                return (
                                  <li key={"svr-langs-"+index}>
                                    <abbr
                                       data-title={item.definition} 
                                       data-desc={item.definitionDescription} 
                                       className={item.class}
                                       onMouseEnter={tagRolloverHandler} 
                                       onMouseOut={tagRollOutHandler}
                                       onFocus={tagRolloverHandler} 
                                       aria-describedby="definition-container"
                                       tabIndex="0"
                                    >{item.name}</abbr>
                                  </li>
                                 )
                                })}
                          </ul>
                          <ul className="frameworks-platforms">
                          {frameworks.map((item, index)=>{
                               return (
                                 <li key={"frameworks-"+index}>
                                <abbr
                                  data-title={item.definition} 
                                  data-desc={item.definitionDescription} 
                                  className={item.class}
                                  onMouseEnter={tagRolloverHandler} 
                                  onMouseOut={tagRollOutHandler}
                                  onFocus={tagRolloverHandler} 
                                  aria-describedby="definition-container"
                                  tabIndex="0"
                                >{item.name}</abbr>
                                </li>
                               )
                             })}
                          </ul>
                          <ul className="technologies">
                          {technologies.map((item, index)=>{
                           
                               return (
                                 <li key={"tech-"+index}>
                                <abbr
                                  data-title={item.definition} 
                                  data-desc={item.definitionDescription} 
                                  className={item.class}
                                  onMouseEnter={tagRolloverHandler} 
                                  onMouseOut={tagRollOutHandler}
                                  onFocus={tagRolloverHandler} 
                                  aria-describedby="definition-container"
                                  tabIndex="0"
                                >{item.name}</abbr>
                                </li>
                               )
                             })}
                          </ul>
                          <ul className="software-tools">
                          {software.map((item, index)=>{
                              return (
                                <li key={"software-"+index}>
                              <abbr
                                data-title={item.definition} 
                                data-desc={item.definitionDescription} 
                                className={item.class}
                                onMouseEnter={tagRolloverHandler} 
                                onMouseOut={tagRollOutHandler}
                                onFocus={tagRolloverHandler} 
                                aria-describedby="definition-container"
                                tabIndex="0"
                              >{item.name}</abbr>
                              </li>
                              )
                             })}
                          </ul>
                      </div>

                  </div>
                </div>

              </div>
            </section>

            <section className="awards" aria-label="Awards I have achieved">
              <div className="info-group">
                <FiAward aria-hidden="true" />
                <h2 className="h2">Awards</h2>
              </div>
              <div className="info">
                <ul>
                    <li>User Experience Foundations Certificate</li>
                    <li>CASE District VII Bronze award, @cal Alumni Network</li>
                    <li>Multiple UC Berkeley Spot Awards</li>
                    <li>Excellence recognition from UC Berkeley Public Affairs </li>
                    <li>Drupal API workshop certificate</li>
                </ul>
              </div>
            </section>

            <section className="interests" aria-label="Interests I have">
              <div className="info-group">
                <RiLightbulbFlashLine aria-hidden="true" />
                <h2 className="h2">Interests</h2>
              </div>
              <div className="info">
                <ul>
                  <li><Link to="/blog/article/we-reached-the-tipping-point">Inventor of alternative transportation as a pedalectric tricycle.</Link></li>
                  <li><Link to="/blog/article/photoshopping-walkable-america">Ecological and urban sustainability.</Link></li>
                  <li><Link to="/blog/tag/3d">3D/CAD design and 3D printing using Solidworks and Fusion 360.</Link></li>
                  <li><Link to="/blog/article/tradition-in-world-of-change">Documentary filmmaking.</Link></li>
                  <li>Construction and metal fabrication. I used to build houses and outdoor art furniture.</li>
                </ul>
              </div>
            </section>

            
        </div>
      </div>
      <p className="sr-only"><Link to="/development">View all development work</Link></p>
    </section>
  )
}

export default ContentAbout
