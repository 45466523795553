import React from 'react'
import { useSelector } from 'react-redux'
import ClientOnly from '../ClientOnly'
import Helmet from '../Helmet';
import Header from '../Header';
import Footer from '../Footer';

import ContentAbout from '../ContentAbout';
import LogoTypeAnimation from '../LogoType'

const AboutLayout = ({ children }) => {
	
	const animation = useSelector(state => state.anim)
    // console.log("animation.played in ABOUT PAGE: ", animation.played);

	return (
	<>
		<Helmet title="About" />
		<Header />

		<ClientOnly>{!animation.played && <LogoTypeAnimation />}</ClientOnly>
		
		<main id="about" className="offset-y">
			<div className="container">
				<ContentAbout />
			</div>
		</main>
		<Footer />
	</>
)};

export default AboutLayout;
